<template>
  <div class="children">
    <div class="title">
      <h1>儿童个人信息保护指引</h1>
    </div>
    <p>
      {{ company }}（以下简称“我们”）尊重并保护{{
        main
      }}应用的儿童个人信息。除《{{
        main
      }}游戏个人信息保护政策》和针对特定产品/服务的个人信息保护指引外，我们还根据相关法律法规，制定了《{{
        main
      }}游戏儿童个人信息保护指引》（以下简称“本指引”）。
    </p>
    <p>
      本指引构成《{{
        main
      }}游戏个人信息保护政策》的一部分，并在其基础上，对儿童个人信息需要加强保护的部分做出增强说明和指引。我们将按照本指引处理儿童个人信息。如在儿童个人信息保护方面有与《{{
        main
      }}游戏个人信息保护政策》有不一致的，以本指引为准。
    </p>
    <p>
      向您特别提示：如我们识别您的孩子为14周岁以下儿童，您需要认证阅读同意《{{
        main
      }}游戏个人信息保护政策》和本指引、完成监护人认证，才能使得您的孩子使用我们的服务。
    </p>
    <p>
      本指引包含了我们处理和保护儿童个人信息的条款，我们建议由监护人（以下简称“您”）完整地阅读本指引，在需要时按照本指引作出您认为适当并能够保护您孩子权利的选择。为了使您充分理解本指引的内容，对于本指引中与您孩子权益存在或可能存在重大关系的条款，我们已采用加粗的方式提示您注意。
    </p>
    <p>
      我们不会制作、发布、传播侵害儿童个人信息安全的信息。我们将遵循合法、正当、必要和诚信的原则，处理儿童个人信息。如果您不同意本指引的任何内容，我们将无法为您孩子提供{{
        main
      }}应用（见关键词定义）的产品/服务（以下简称“{{
        main
      }}产品/服务”），您应引导您的孩子立即停止使用{{
        main
      }}产品/服务。当本指引进行更新后，我们会及时在{{
        main
      }}应用发布最新版本，建议您及时关注并阅读。
    </p>
    <p>
      如果对《儿童个人信息保护协议》有任何问题，您可以通过企业官网提供的联系方式与我们取得联系。
    </p>
    <p>本指引的要点如下：</p>
    <p>
      ●
      本指引向您说明每项业务功能处理的您孩子个人信息的种类、处理目的、方式，以及拒绝提供您孩子个人信息可能导致的结果。
    </p>
    <p>● 本指引将严格保障儿童权利，包括查询、复制、更正、删除等权利。</p>
    <p>
      ●
      为保护儿童个人信息安全，本指引将向您说明我们所采用的安全策略及技术措施。我们为您孩子提供符合行业要求的个人信息安全保护水平。
    </p>
    <p>
      ●
      我们将依法及本指引规定处理您孩子的个人信息，如果超过本指引所述范围收集、使用您孩子的个人信息，我们会再次向您告知并取得您的同意。
    </p>
    <p>本指引包含以下内容：</p>
    <p>1. 如何收集和使用您孩子的信息</p>
    <p>2. 如何使用Cookie及相关技术</p>
    <p>3. 您孩子个人信息的分享</p>
    <p>4. 如何委托处理、提供、转让、公开您孩子的个人信息</p>
    <p>5. 如何保存您孩子的个人信息</p>
    <p>6. 您和您孩子的权利</p>
    <p>7. 如何保护您孩子的个人信息</p>
    <p>8. 本指引的变更</p>
    <p>9. 关键词定义</p>
    <p>1.如何收集和使用您孩子的信息</p>
    <p>
      我们根据合法、正当、必要、诚信的原则，仅收集实现{{
        main
      }}产品/服务功能所必要的信息。
    </p>
    <p>
      在您孩子使用{{
        main
      }}产品/服务的过程中，可能会主动提供信息，或我们也可能收集您孩子因为使用{{
        main
      }}产品/服务而产生的信息，用于向您孩子提供{{ main }}产品/服务、优化{{
        main
      }}产品/服务以及保障账号安全。
    </p>
    <p>1.1 注册功能</p>
    <p>
      您理解并同意，如您的孩子希望使用{{
        main
      }}产品/服务，首先需要注册一个完美通行证或{{ main }}手游账号成为{{
        main
      }}的用户。在注册时，您和/或您孩子需要向我们提供手机号码或邮箱账号（可选），我们将通过发送短信验证码或邮件的方式来验证手机号码/邮箱账号是否有效。
    </p>
    <p>
      为满足相关法律法规政策及相关主管部门的要求，您孩子需进行实名认证以继续使用{{
        main
      }}产品/服务，我们会在实名认证情况下收集您孩子的身份证信息、姓名，该信息属于敏感个人信息，拒绝提供该信息会导致您孩子无法使用我们的服务。如我们识别您的孩子为14周岁以下儿童，您需要认真阅读同意《{{
        main
      }}游戏个人信息保护政策》和本指引、完成监护人认证，才能使得您的孩子使用我们的服务。
    </p>
    <p>
      您的孩子还可以通过登录您孩子所注册的与我们合作的第三方平台（例如应用商店或渠道、微信等）的相关账户使用{{
        main
      }}产品与/或服务，此时我们会从第三方平台共享您孩子在该等第三方账号项下的相关信息，包括账户名称、账户昵称（但不包括实名认证信息等）。就该等第三方平台所收集的您孩子的个人信息，适用第三方平台的个人信息保护政策或相关声明。
    </p>
    <p>
      为教育引导您孩子增强个人信息保护意识和能力，保护您孩子的个人信息安全，我们强烈建议上述注册过程由您来帮助孩子完成。
    </p>
    <p>1.2 保障账号和服务安全</p>
    <p>
      为保障您孩子的账号安全，在出现异常登录情形时，我们将发送短信进行提示，为此，我们将自动收集您孩子在使用{{
        main
      }}产品/服务时的IP地址。
    </p>
    <p>1.3 信息展示和搜索功能</p>
    <p>
      为了让您的孩子快速地找到其所需要的{{
        main
      }}产品/服务，我们可能会收集您孩子使用{{
        main
      }}产品/服务的个人常用设备信息，包括硬件型号、设备厂商、设备类型、操作系统类型、设备序列号、唯一设备标识符，并通过对上述设备信息进行关联来为您孩子提供商品信息展示的最优方式。为了不断改进和优化{{
        main
      }}产品/服务的功能，我们可能使用您孩子的上述个人信息。此外，我们会保留您孩子的搜索内容以减少重复输入的次数来为您孩子展示与其搜索内容相关联的商品。
    </p>
    <p>
      请您注意，您孩子的搜索关键词信息无法单独识别您孩子的身份，不属于个人信息，我们有权以任何的目的对其进行使用；只有当您孩子的搜索关键词信息与您孩子的其他信息相互结合使用并可以识别您孩子的身份时，则在结合使用期间，我们会将您孩子的搜索关键词信息作为个人信息，与您孩子的搜索历史记录一同按照本指引对其进行处理与保护。
    </p>
    <p>1.4 客服与客诉</p>
    <p>
      在您/您孩子与我们的在线客服与/或电话客服进行沟通时，为了及时有效地与您/您孩子沟通，我们的在线客服和电话客服会要求您提供您/您孩子的账号信息以核验身份。
    </p>
    <p>
      为保证您的账号安全，在客服功能下我们会要求您提供您的账号信息、姓名、所在游戏、所在产品及其服务器、游戏角色名称及ID等信息以核验您的身份。
    </p>
    <p>
      我们的部分客服功能（如账号争议解决、账号充值异常被封等对您的账号权益有重⼤影响的客服功能）还可能会要求您提供身份信息、（手持）身份证照片、银行账号和/或支付信息（涉及支付或退款时）以达到验证您身份的目的，防止您的身份被冒用。在您使用相应客服功能时，如我们需要您提供上述敏感个人信息的，我们会通过弹窗告知您《账号与身份验证个人信息保护规则》，在取得您的同意后，我们才会处理您的上述敏感个⼈信息。如您发现您的孩子与我们进行联系，请您及时告知我们，我们会向您的孩子提示在您的陪同和监护下进行，或请您来与我们进行沟通。
    </p>
    <p>1.5 设备权限调用</p>
    <p>
      我们在提供{{
        main
      }}产品/服务的过程中，可能需要开启一些设备访问权限，相关系统权限调用的具体情况详见《{{
        main
      }}游戏个人信息保护政策》或{{ main }}旗下其他应用的个人信息保护指引。
    </p>
    <p>2. 如何使用Cookie及相关技术</p>
    <p>
      我们使用Cookies来保持{{
        main
      }}产品/服务的可靠性、安全性和个性化。您可通过阅读《Cookie政策》了解具体内容。
    </p>
    <p>
      我们也会使用我们自研SDK和第三方SDK实现信息的自动采集和使用，相关收集信息的内容、频率及使用目的请参见《{{
        main
      }}游戏个人信息保护政策》或{{ main }}旗下其他应用的个人信息保护指引。
    </p>
    <p>3. 您孩子个人信息的分享</p>
    <p>
      3.1
      {{ main }}产品/服务，可让您孩子不仅与自己的社交网络，也与使用该{{
        main
      }}产品/服务的其他用户公开分享您孩子的相关信息，例如，您孩子在{{
        main
      }}产品/服务中所上传或发布的信息（包括您孩子公开的个人信息）、您孩子对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。此外，您在使用{{
        main
      }}产品/服务时自行主动提供、上传或发布的内容和信息，可能会包含您孩子的个人信息，请您谨慎提供。
    </p>
    <p>
      3.2
      为教育引导您孩子增强个人信息保护意识和能力，保护您孩子的个人信息安全，我们强烈建议3.1条所述功能由您来操作，并请您谨慎考虑，是否在使用{{
        main
      }}产品/服务时自行主动披露您孩子的个人信息。
    </p>
    <p>
      3.3 您可通过{{
        main
      }}产品/服务中的设置或根据我们的家长监护系统服务流程删除您孩子的个人信息。但请您注意，这些信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公共领域保存。
    </p>
    <p>4. 如何委托处理、提供、转让、公开您孩子的个人信息</p>
    <p>4.1 委托处理、提供</p>
    <p>
      为了向您提供更完善、优质的产品/服务，某些功能可能由我们的服务合作方提供（包括我们的第三方服务供应商、承包商、代理），我们会委托服务合作方代表我们处理您孩子的某些个人信息。
    </p>
    <p>
      当我们委托第三方处理或对外提供您孩子个人信息的，我们会根据法律、行政法规的要求，自行或委托第三方机构进行个人信息保护评估，采用加密、去标识化等手段保障您孩子的信息安全。我们还会要求合作方签署数据处理协议，按照我们的说明、个人信息保护政策以及其他相关的安全措施来为我们处理上述信息，并用于实现本指引所述目的。如要改变您孩子个人信息的处理目的，该等受托方将再次取得您的同意。
    </p>
    <p>
      我们不会向{{
        main
      }}以外的其他个人信息处理者提供您孩子的个人信息，除非具备合法性基础（例如：基于法定义务所必需；基于您的同意处理个人信息）。
    </p>
    <p>
      对于您孩子的个人信息，我们仅在本指引所述的目的和范围内或根据法律法规的要求进行处理。
    </p>
    <p>4.2 转让</p>
    <p>
      随着我们业务的持续发展，我们以及我们的关联企业有可能进行合并、收购、资产转让或类似的交易，个人信息有可能作为此类交易的一部分而被转移。如涉及到个人信息转让，我们将向您告知接收方的名称或者姓名和联系方式，并会要求新的持有您孩子个人信息的公司、组织继续受本指引的约束。如果本指引中约定的个人信息的处理方式发生任何改变，我们将要求该公司、组织重新取得您的同意。
    </p>
    <p>4.3 公开</p>
    <p>
      除非取得您的有效同意或具备其他合法性基础，我们不会向社会或不特定人群公开您孩子的个人信息。
    </p>
    <p>5. 如何保存您孩子的个人信息</p>
    <p>5.1 信息存储的方式和期限</p>
    <p>
      我们会通过安全的方式加密保存您孩子的个人信息，包括本地存储、数据库和产品/服务器日志。
    </p>
    <p>
      我们只会在达成本指引所述目的所必需的最短期限内保存您孩子的个人信息，其中当我们作为数据处理者而提供的某款{{
        main
      }}产品/服务下架、停服时，该款{{
        main
      }}产品/服务内的数据（包括行为数据、角色资料等数据）将会被删除或进行匿名化处理。在超出保存期限后，我们会对您孩子的个人信息进行删除或者匿名化处理，但国家法律法规或规范性文件等另有要求或为履行我们的合规义务或者删除个人信息从技术上难以实现的，我们会停止除存储和采取必要的安全保护措施之外的处理。
    </p>
    <p>5.2 信息存储的地域</p>
    <p>
      我们不会将您孩子的个人信息传输至境外，我们仅将在中国大陆境内运营处理的您孩子的个人信息存储在中国大陆境内。
    </p>
    <p>6. 您如何管理您孩子的个人信息</p>
    <p>
      6.1 除根据《{{ main }}游戏个人信息保护政策》及针对特定{{
        main
      }}产品/服务的特定个人信息保护政策中的权利外，您可以通过控制您孩子的账户，更改、删除您孩子的个人信息，以保护您孩子的信息安全及身心健康。
    </p>
    <p>
      6.2
      如您/您孩子发现我们收集、存储、使用、披露的儿童个人信息有误，您可以通过我们的家长监护认证向我们申请监护服务。我们会根据您的要求和相关流程，更正您孩子的账户设置和个人信息。
    </p>
    <p>6.3 如您发现我们存在如下情况时，您可以要求我们删除：</p>
    <p>（1） 处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
    <p>（2） 我们停止提供产品/服务，或者保存期限已届满；</p>
    <p>（3） 您撤回同意；</p>
    <p>（4） 我们违反法律、行政法规或者违反本指引约定处理个人信息；</p>
    <p>（5） 法律、行政法规规定的其他情形。</p>
    <p>7. 如何保护您孩子的个人信息</p>
    <p>
      7.1
      为保障您孩子的信息安全，我们会在现有技术水平下努力采取合理必要的物理、技术和行政管理方面的安全措施来保护您孩子的信息，以防止您孩子的信息遭受违法复制、丢失、误用、非授权访问、下载、披露和更改，包括但不限于SSL、信息加密存储、数据中心的访问控制、专用的网络通道及代理。
    </p>
    <p>
      7.2
      我们建立专门的管理制度、流程、组织并采取相应措施确保信息安全。对于工作人员，我们将以最小授权为原则，严格设定信息访问权限，控制儿童个人信息的知悉范围。如需访问儿童个人信息，我们将要求经过儿童个人信息保护负责人或其授权的管理人员审批，并对访问情况进行记录。此外，我们对可能接触到您孩子个人信息的员工或外包人员进行严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施；组织对员工的数据安全或合规培训等。
    </p>
    <p>
      7.3
      若我们确认发生个人信息泄露等安全事件，我们会立即启动应急预案，采取补救措施，阻止安全事件扩大，如涉及您孩子的个人信息，我们会将事件相关情况以邮件、信函、电话、推送通知等方式向您告知；如难以逐一告知，我们会采取公告的形式发布相关警示信息。同时，我们还将按照监管部门要求上报个人信息安全事件的处置情况。
    </p>
    <p>8. 本指引的变更</p>
    <p>我们可能适时修订本指引的条款，该等修订构成本指引的一部分。</p>
    <p>
      对于实质性变化（如处理您孩子的个人信息的目的、方式和范围等），我们将在修订生效前通过在应用的显著位置提示或向您发送电子邮件或以其他方式发布对本指引所做的变更。
    </p>
    <p>9. 关键词定义</p>
    <p>9.1 用户或您：指使用{{ main }}产品/服务的儿童的监护人。</p>
    <p>9.2 儿童或您孩子：指您所监护的年龄不超过14周岁的未成年人。</p>
    <p>
      9.3
      中国大陆或中国大陆境内：指中华人民共和国大陆境内，不包含香港特别行政区、澳门特别行政区和台湾地区。
    </p>
    <div class="date">
      <p>更新日期：{{ Date3 }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'privacy',
  data () {
    return {
      Date1: '',
      Date2: '',
      gameName: '',
      company: '',
      Date3: '',
      main: ''
    }
  },
  mounted () {
    this.Date1 = this.$utils.getUrlKey('Date1')
    this.Date2 = this.$utils.getUrlKey('Date2')
    this.gameName = this.$utils.getUrlKey('gameName')
    this.company = this.$utils.getUrlKey('company')
    this.Date3 = this.$utils.getUrlKey('Date3')
    this.main = this.$utils.getUrlKey('main')
  }
}
</script>

<style lang="less" scoped>
.children {
  font-size: 18px;
  .title {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
  .date {
    margin-top: 80px;
    text-align: right;
  }
}
</style>
